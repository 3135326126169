<template>
    <div class="download-page">
        <div class="wrap">
            <div class="box page-title">
                <a class="item active" href="javascript:void(0)" @click="toUrl('/downloadAcr')">ACR预设</a>
                <a class="item" href="javascript:void(0)" @click="toUrl('/downloadSoftware')">软件</a>
                <a class="item" href="javascript:void(0)" @click="toUrl('/downloadMaterial')">素材</a>
            </div>
            <div class="box main-con">
                <el-popover
                    v-for="(item,index) in acrList"
                    :key="index"
                    placement="bottom-start"
                    trigger="hover"
                >
                    <div class="acr-list z-flex">
                        <a :class="['acr-item',activeId==subItem.id?'active':'']" href="javascript:;" @click="showAcr(subItem)" v-for="(subItem,subIdx) in item.acrs" :key="subIdx">
                            <img class="acr-img" :src="subItem.image_after+'?x-oss-process=image/resize,m_fill,h_300,w_300'"/>
                            <p class="acr-name">{{subItem.title}}</p>
                        </a>
                    </div>
                    <el-button slot="reference" :type="activeType==item.id?'primary':''" class="acr-title">{{item.name}}</el-button>
                </el-popover>
                <div class="acr-con">
                    <div class="title">{{acrInfo.fulltitle}}</div>
                    <div class="price">
                        <span class="price-now"><em>￥</em>{{isVip ? acrInfo.charge : acrInfo.price}}</span>
                        <span class="price-ori" v-if="isVip"><em>￥</em>{{acrInfo.price}}</span>
                    </div>
                    <div class="vip-tips z-flex">
                        <span class="viplabel">会员</span>
                        <span v-if="isVip">您已享受会员优惠价</span>
                        <span v-else>开通会员后下单预计可省{{acrInfo.price - acrInfo.charge}}元 <a href="" style="color:#0077fa">立即开通</a></span>
                    </div>
                    <el-button-group class="btn-group">
                        <el-button class="btn" icon="el-icon-question" @click="toHelp">使用教程</el-button>
                        <el-button class="btn" icon="el-icon-s-tools" v-if="isBuy" @click="toMyAcrs">进入我的预设</el-button>
                        <el-button class="btn" v-else @click="toBuyAcr" ><i class="iconfont el-icon--left">&#xe882;</i>WIN版预设购买</el-button>
                    </el-button-group>
                    <div class="contrast-con">
                        <p style="color:#777;font-weight: 700; text-align:center;margin:20px 0;">样品赏析</p>
                        <div class="img-wrap" ref="imgBox" v-if="acrInfo.show_mode=='1'">
                            <el-button 
                                class="btn-showOri" 
                                type="primary" 
                                @mousedown.native="showOri = true" 
                                @mouseup.native="showOri = false"
                            >
                                {{showOri?'松开鼠标左键查看效果图':'点击鼠标左键查看原图'}}
                            </el-button>
                            <div class="img-list">
                                <img class="img-item img-ori" :src="acrInfo.image_before" />
                                <img class="img-item img-ps" v-show="!showOri" :src="acrInfo.image_after" />
                            </div>
                        </div>
                        <div class="img-box" ref="imgBox" @mousemove="moveLine" v-else>
                            <div class="img-before" ref="imgBefore" :style="`background:url(${acrInfo.image_before}) no-repeat 0 0;background-size:cover`"></div>
                            <div class="img-after" ref="imgAfter" :style="`background:url(${acrInfo.image_after}) no-repeat 0 0;background-size:cover`"></div>
                            <div class="line" ref="line">
                                <span class="label-before">修图前</span>
                                <span class="label-after">修图后</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import {mapState} from "vuex";

    export default {
        components: {
            
        },
        data() {
            return {
                activeType:'',
                activeId:'0',
                acrList:[],
                acrInfo:{},
                isBuy:false,//是否购买
                isVip:false,//是否Vip
                showOri:false,//查看原图
            };
        },
        computed: {
            ...mapState(['vuex_user'])
        },
        mounted() {
            this.activeId = this.$route.query.id || 0;
            this.$axios.api.downAcr({id: this.activeId}, (res) => {
                if (res.code == 0) {
                    this.acrList = res.data.list;
                    this.acrInfo = res.data.acr;
                    this.activeId = res.data.acr.id;
                    this.isBuy = res.data.isBuy;
                    this.isVip = res.data.isVip;
                    this.activeType = res.data.acr.type_id;
                } else {
                    this.$message.warning(res.msg);
                }
            });
        },
        methods: {
            showAcr(item){
                this.activeId = item.id;
                this.activeType = item.type_id;
                this.$axios.api.downAcr({id: this.activeId}, (res) => {
                    if (res.code == 0) {
                        this.acrInfo = res.data.acr;
                        this.isBuy = res.data.isBuy;
                        this.isVip = res.data.isVip;
                    } else {
                        this.$message.warning(res.msg);
                    }
                });
            },
            toBuyAcr(){
                if (!this.vuex_user) {
                    //登录窗口
                    return this.$parent.$refs.navcom.login();
                }
                if (parseInt(this.acrInfo.price) > 0) {
                    //会员卡免费
                    this.$axios.api.acrFreeGive({acr_id: this.activeId}, (res) => {
                        if (res.code == 200) {
                            this.$wwwRouter.open("myPreset");
                        } else {
                            this.$message.warning(res.msg);
                        }
                    });
                } else {
                    this.$wwwRouter.open("pay/acr/"+this.activeId);
                }
            },
            toMyAcrs(){
                this.$wwwRouter.open("myPreset");
            },
            toUrl(url){
                this.$router.push({
                    path:url
                })
            },
            toHelp(){
                this.$router.push({
                    path:'/help/3'
                })
            },
            //鼠标滑动
            moveLine(e){
                var left = this.$refs.imgBox.offsetLeft;

                // 计算出需要偏移的距离
                var offsetNO = e.pageX - left;

                var ww = this.$refs.imgBox.clientWidth - 100;
                // 默认原图最小显示100px，最大显示884px
                if (offsetNO < 100) {
                    this.$refs.imgBefore.style.width = 100 + 'px';
                    this.$refs.line.style.left = 100 + 'px';
                } else if (offsetNO > ww) {
                    this.$refs.imgBefore.style.width = ww + 'px';
                    this.$refs.line.style.left = ww + 'px';
                } else {
                    this.$refs.imgBefore.style.width = offsetNO + 'px';
                    this.$refs.line.style.left = offsetNO + 'px';
                }
            }
        },
    };
</script>

<style lang="scss" scoped>
.box{
    width:calc(100% - 60px);
    padding:20px 30px;
    background:#fff;
    border-radius: 4px;
    margin-top: 10px;
}   
.page-title{
    margin-top: 30px;
    .item{
        font-size: 18px;
        padding: 0 20px;
        margin:0 20px;
        &.active{
            color:$colorMain;
        }
        &:hover{
            color:$colorMain;
        }
    }
}
.main-con{
    .acr-title{
        margin: 0 10px 10px 0;
    }
}
.acr-list{
    width:1200px;
    .acr-item{
        width:100px!important;
        margin:10px;
        text-align: center;
        .acr-img{
            width:100%;
            height:100px;
        }
        .acr-name{
            width:100%;
            @extend %textOverflow;
        }
        &:hover{
            .acr-name{
                color:$colorMain;
            }
        }
        &.active{
            .acr-name{
                color:$colorMain;
            }
        }
    }
}
.acr-con{
    margin-top: 30px;
    position: relative;
    .title{
        font-weight: 700;
        color: #474747;
        font-size: 22px;
        margin-bottom: 15px;
    }
    .price{
        font-family: Arial, Helvetica, sans-serif;
        margin-bottom: 15px;
        .price-now{
            color: #ff3738;
            font-size: 32px;
            font-weight: 700;
            margin-right: 15px;
            em{
                font-size: 20px;
                font-style: normal;
                font-weight: normal;
            }
        }
        .price-ori{
            font-size: 20px;
            color: #777;
            text-decoration: line-through;
            em{
                font-size: 16px;
                font-style: normal;
            }
        }
    }
    .vip-tips{
        color: #777;
        font-size: 15px;
        .viplabel{
            background: #eee url(https://edu.dyyxclub.com/images/icons/vip.png) no-repeat 15px center;
            background-size: auto 22px;
            padding: 0 15px 0 45px;
            height: 30px;
            line-height: 30px;
            display: inline-block;
            border-radius: 15px;
            color: #e1a500;
            margin-right: 10px;
        }
    }
    .btn-group{
        position:absolute;
        right: 0;
        top: 45px;
    }
}
.contrast-con .img-box{
  width: 984px;
  height: 656px;
  background: #eee;
  margin: 12px auto;
  position: relative;
}
.contrast-con .img-before{
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  width: 100px;
  z-index: 1;
  background-size: cover;
} 
.contrast-con .img-after{
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  background-size: cover;
} 
.contrast-con .line{
  width: 2px;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    top: 0;
    z-index: 3;
    left: 100px;
}
.contrast-con .line .label-before{
  width: 60px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  display: block;
  position: absolute;
  left: -70px;
  top: 50px;
  background: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
  color: #000;
}
.contrast-con .line .label-after{
  width: 60px;
  height: 26px;
  text-align: center;
  line-height: 26px;
  display: block;
  position: absolute;
  left: 10px;
  top: 20px;
  background: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 5px;
}
.contrast-con .img-list{
  width: 1000px;
  margin: 0 auto;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  left:inherit!important;
}
.contrast-con .img-wrap .img-item{
  max-width: 100%;
}
.contrast-con .img-wrap .img-ps{
  position:absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.contrast-con .btn-showOri{
    margin: 0 0 20px 70px;
}
</style>
